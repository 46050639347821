import React from "react";
import PropTypes from "prop-types";
import { Box, FormLabel } from "@chakra-ui/react";
import Input from "./Input";

const InputWithLabel = React.forwardRef(
  (
    {
      label,
      labelProps,
      boxProps,
      isInvalid,
      backgroundColor = "#fff",
      children,
      labelBorder,
      ...rest
    },
    ref
  ) => {
    return (
      <Box position="relative" {...boxProps}>
        <FormLabel
          borderRadius={"5px"}
          border={labelBorder}
          position="absolute"
          top="-0.8em"
          left="16px"
          backgroundColor={backgroundColor}
          fontSize="12px"
          zIndex="10"
          padding="0 8px"
          {...(isInvalid && { color: "red" })}
          {...labelProps}
        >
          {label}
        </FormLabel>
        <Input
          ref={ref}
          isInvalid={isInvalid}
          backgroundColor={backgroundColor}
          {...rest}
        />
        {children}
      </Box>
    );
  }
);

InputWithLabel.propTypes = {
  label: PropTypes.string,
  backgroundColor: PropTypes.string,
  labelProps: PropTypes.object,
  boxProps: PropTypes.object,
  isInvalid: PropTypes.bool,
  children: PropTypes.node,
  labelBorder: PropTypes.string,
};

InputWithLabel.displayName = "InputWithLabel";

export default InputWithLabel;
