import React from "react";
import PropTypes from "prop-types";
import { Input as ChakraInput } from "@chakra-ui/react";
import { supportedColorLabels } from "../utils/propTypes";

const normalState = {
  borderColor: "orchidGrey200",
  _hover: { borderColor: "orchidGrey600" },
  _focus: { borderColor: "orchidGrey600" },
};

const inputErrorState = {
  borderColor: "red",
  _hover: { borderColor: "red" },
  _focus: { borderColor: "red" },
};

const Input = React.forwardRef(({ isInvalid, ...rest }, ref) => {
  return (
    <ChakraInput
      ref={ref}
      borderRadius="2px"
      height="auto"
      padding="12px 24px"
      backgroundColor="white"
      _disabled={{ cursor: "initial", opacity: 0.6 }}
      {...(isInvalid ? inputErrorState : normalState)}
      {...rest}
    />
  );
});

Input.propTypes = {
  isInvalid: PropTypes.bool,
  color: supportedColorLabels,
};

Input.displayName = "Input";

export default Input;
